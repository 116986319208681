import styled from 'styled-components';
import {COLORS, FONTS, SIZES, Typography} from '@fupa/fupa-uikit';
import {LinkOnComponent} from 'app/components/links/link';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding: ${SIZES['16']} ${SIZES['16']} ${SIZES['8']} ${SIZES['16']};
  height: 3.75rem;
  width: 100%;

  ${props =>
    props.theme.desktopLayout`
      height: ${SIZES['64']};
    `}
`;

const IconContainer = styled.div<{color: string}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${SIZES['36']};
  width: ${SIZES['36']};
  margin-right: ${SIZES['8']};
  background-color: ${props => props.color};
  border-radius: 50%;
  font-size: ${SIZES['20']};

  ${props =>
    props.theme.desktopLayout`
      height: ${SIZES['40']};
      width: ${SIZES['40']};
      margin-right: ${SIZES['16']};
      font-size: ${SIZES['24']};
    `}
`;

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

const Title = styled(Typography)`
  &&& {
    font-size: ${FONTS['14']};
    line-height: ${SIZES['20']};

    ${props =>
      props.theme.streamModuleFourElemsPerViews`
        font-size: ${FONTS['18']};
        line-height: ${SIZES['24']};
      `}
  }
`;

const Subtitle = styled(Typography)`
  &&& {
    line-height: ${SIZES['16']};
    ${props =>
      props.theme.streamModuleFourElemsPerViews`
        font-size: ${FONTS['14']};
        line-height: ${SIZES['20']};
      `}
  }
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
`;

const StyledLink = styled(LinkOnComponent)`
  color: ${COLORS.darkerGrey};
  &:hover,
  &:focus {
    color: ${COLORS.fupaSecondary};
  }
`;

const LinkText = styled(Typography)`
  &&& {
    color: inherit;

    ${props =>
      props.theme.desktopLayout`
        font-size: ${FONTS['14']};
        line-height: ${SIZES['20']};
      `}
  }
`;

export {Wrapper, IconContainer, Heading, Title, Subtitle, LinkContainer, StyledLink, LinkText};
