import CompetitionModuleData from 'app/components/competitionModule/models/CompetitionModuleData';
import {InfiniteData} from '@tanstack/react-query';
import DistrictCompetitionsResponse from 'app/components/competitionModule/models/DistrictCompetitionsResponse';
import AdSlotModel from 'app/components/ads/AdSlotModel';
import {AdUnit} from 'app/components/ads/AdUnit';
import {AdLayout} from 'app/components/ads/AdLayout';

type AdSlotConfig = {
  index: number;
  adLayout: AdLayout;
  adUnit: AdUnit;
};

export default class InfiniteCompetitionModules {
  readonly competitionModules: CompetitionModuleData[];

  constructor(data: InfiniteData<DistrictCompetitionsResponse> | undefined) {
    this.competitionModules = data ? data.pages.map(page => page.data).flat() : [];
  }

  hasModules(): boolean {
    return this.competitionModules.length > 0;
  }

  getFirstCompetitionModule(): CompetitionModuleData | undefined {
    return this.competitionModules[0];
  }

  getInfiniteList(): (CompetitionModuleData | AdSlotModel)[] {
    const moduleList = this.competitionModules.slice(1);
    return this.addAdSlotsToInfiniteList(moduleList);
  }

  private addAdSlotsToInfiniteList(list: CompetitionModuleData[]): (CompetitionModuleData | AdSlotModel)[] {
    const adSlots: AdSlotConfig[] = [
      {index: 2, adLayout: 'mobile', adUnit: AdUnit.Rectangle_1},
      {index: 3, adLayout: 'desktop', adUnit: AdUnit.Superbanner_3},
      {index: 6, adLayout: 'mobile', adUnit: AdUnit.Content_2},
      {index: 9, adLayout: 'mobile', adUnit: AdUnit.Rectangle_2},
    ];

    const listWithAdSlots: (CompetitionModuleData | AdSlotModel)[] = [...list];

    adSlots.forEach(slot => {
      if (listWithAdSlots.length > slot.index) {
        listWithAdSlots.splice(slot.index, 0, new AdSlotModel({adLayout: slot.adLayout, adUnit: slot.adUnit}));
      }
    });

    return listWithAdSlots;
  }
}
