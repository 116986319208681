import {ImageInterface} from 'app/types/ImageInterface';
import PlayerInterface from 'app/models/player/PlayerInterface';
import PlayerLink from 'app/models/player/PlayerLink';

export default class PlayerModel {
  private readonly id: number;
  readonly slug: string;
  private readonly firstName: string;
  private readonly lastName: string;
  readonly image: ImageInterface;
  private readonly link: PlayerLink;

  constructor(data: PlayerInterface) {
    this.id = data.id;
    this.slug = data.slug;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.image = data.image;
    this.link = new PlayerLink(data);
  }

  getLink(): string {
    return this.link.getLink();
  }

  getName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
