import Linkable from 'app/models/Linkable';
import NewsInterface from 'app/models/news/NewsInterface';

export default class NewsLink implements Linkable {
  constructor(private readonly news: NewsInterface) {}

  getLink(): string {
    return `/news/${this.news.slug}`;
  }

  getLocationState(): {updatedAt: number} | undefined {
    if (!this.news.updatedAt) {
      return;
    }
    return {updatedAt: Math.round(new Date(this.news.updatedAt).getTime() / 1000)};
  }
}
