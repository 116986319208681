import AdSlotInterface from 'app/components/ads/AdSlotInterface';
import {AdUnit} from 'app/components/ads/AdUnit';
import {AdLayout} from 'app/components/ads/AdLayout';

export default class AdSlotModel implements AdSlotInterface {
  readonly adLayout: AdLayout;
  readonly adUnit: AdUnit;

  constructor(data: AdSlotInterface) {
    this.adLayout = data.adLayout;
    this.adUnit = data.adUnit;
  }
}
