import styled from 'styled-components';
import {COLORS, SIZES} from '@fupa/fupa-uikit';
import {styled as styledMui} from '@mui/material/styles';
import {Button} from '@mui/material';

const LinkWrapper = styled.div`
  display: flex;
  padding: 0.25rem 0;
  margin: 0 1rem;
  border-top: ${SIZES['1']} solid ${COLORS.lighterGrey};
  justify-content: space-around;
  color: ${COLORS.darkerGrey};
`;

const LinkButton = styledMui(Button)({
  width: '100%',
  '&:hover, &:focus': {
    backgroundColor: `${COLORS.lighterGrey}`,
  },
});

export {LinkWrapper, LinkButton};
