import DateModel from 'app/models/DateModel';
import NewsInterface from 'app/models/news/NewsInterface';
import NewsModel from 'app/models/news/NewsModel';

export interface NewsModuleNewsInterface extends NewsInterface {
  subject: string;
}

export default class NewsModuleNews {
  readonly news: NewsModel;
  readonly subject: string;

  constructor(data: NewsModuleNewsInterface) {
    this.news = new NewsModel(data);
    this.subject = data.subject;
  }

  getLink(): {pathname: string; state?: {updatedAt: number}} {
    return this.news.getLink();
  }

  formatDateRelative(relativeTo?: DateModel): string {
    return this.news.formatPublishDateRelative(relativeTo);
  }
}
