import styled from 'styled-components';
import {COLORS, SIZES} from '@fupa/fupa-uikit';

const ModuleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${COLORS.white};
  max-width: ${props => props.theme.mobileContentWidth}px;
  height: fit-content;

  ${props =>
    props.theme.desktopLayout`
      border-radius: ${SIZES['4']};
      border: 1px solid ${COLORS.lightGrey};
      max-width: ${props.theme.elementsWidth2Columns}px;
    `}
`;

const Separator = styled.div`
  height: ${SIZES['1']};
  background-color: ${COLORS.lighterGrey};
  margin: 0 ${SIZES['8']} 0 ${SIZES['16']};

  ${props =>
    props.theme.desktopLayout`
      margin: 0 ${SIZES['16']};
    `}
`;

export {ModuleWrapper, Separator};
