import {useMemo} from 'react';
import {useLocation} from 'react-router';
import {PaginatedResponse} from 'app/models/pagination/PaginatedResponse';

export const useInfiniteQueryLinks = <T extends PaginatedResponse>(
  data: T[]
): {prevLink: string | null; nextLink: string | null} => {
  const location = useLocation();

  const nextLink = useMemo((): string | null => {
    const lastPage = data[data.length - 1];
    const nextParams = lastPage.getNextLinkParams();
    if (!nextParams) {
      return null;
    }
    return `${location.pathname}${nextParams}`;
  }, [data]);

  const prevLink = useMemo((): string | null => {
    if (!nextLink) {
      return null;
    }
    const prevParams = data[0].getPrevLinkParams();
    if (!prevParams) {
      return null;
    }
    return `${location.pathname}${prevParams}`;
  }, [data]);

  return {nextLink, prevLink};
};
