export enum ProfileRole {
  Player = 'player',
  PlayerCoach = 'player-coach',
  AssistantPlayerCoach = 'assistant-player-coach',
  Coach = 'coach',
  AssistantCoach = 'assistant-coach',
  GoalkeeperCoach = 'goal-keeper-coach',
  Advisor = 'advisor',
  Masseur = 'masseur',
  TeamDoctor = 'team-doctor',
  TeamManager = 'team-manager',
  Physio = 'physio',
  ConditioningCoach = 'conditioning-coach',
  FitnessCoach = 'fitness-coach',
  SportsDirector = 'sports-director',
  SportsManager = 'sports-manager',
  AthleticTrainer = 'athletic-trainer',
}

export const ProfileRoleDisplayNames: {[key in ProfileRole]: string} = {
  [ProfileRole.Player]: 'Spieler',
  [ProfileRole.PlayerCoach]: 'Spielertrainer',
  [ProfileRole.AssistantPlayerCoach]: 'spielender Co-Trainer',
  [ProfileRole.Coach]: 'Trainer',
  [ProfileRole.AssistantCoach]: 'Co-Trainer',
  [ProfileRole.GoalkeeperCoach]: 'Torwart-Trainer',
  [ProfileRole.Advisor]: 'Betreuer',
  [ProfileRole.Masseur]: 'Masseur',
  [ProfileRole.TeamDoctor]: 'Mannschaftsarzt',
  [ProfileRole.TeamManager]: 'Teammanager',
  [ProfileRole.Physio]: 'Physiotherapeut',
  [ProfileRole.ConditioningCoach]: 'Konditionstrainer',
  [ProfileRole.FitnessCoach]: 'Fitnesstrainer',
  [ProfileRole.SportsDirector]: 'Sportdirektor',
  [ProfileRole.SportsManager]: 'sportlicher Leiter',
  [ProfileRole.AthleticTrainer]: 'Athletiktrainer',
};
