import NewsModuleNews from 'app/components/topNewsModule/models/NewsModuleNews';
import {api} from 'app/services/api';
import {updateHistory} from 'app/actions/historyActions';
import {updateHttpError} from 'app/components/error/ErrorHandlerActions';
import {AxiosError, AxiosResponse} from 'axios';
import DistrictNewsResponse, {DistrictNewsResponseRaw} from 'app/components/topNewsModule/models/DistrictNewsResponse';
import {fetchNextApiCall} from 'app/services/fetchNextApiCall';
import FuPaApiError from 'app/models/error/FuPaApiError';
import getParamsStringFromOptions, {QueryOptions} from 'app/queries/common/getParamStringFromOptions';

const fetchDistrictNews = async (slug: string, options?: QueryOptions): Promise<DistrictNewsResponseRaw> => {
  let url = `/districts/${slug}/news${options ? `?${getParamsStringFromOptions(options)}` : ''}`;
  const response: AxiosResponse<DistrictNewsResponseRaw> = await api.get(url);
  return response.data;
};

const topNewsOptions: QueryOptions = {maxDays: 7, limit: 25};

const fetchTopNewsList = async (slug: string): Promise<NewsModuleNews[]> => {
  try {
    const response = await fetchDistrictNews(slug, topNewsOptions);
    return response.data.map(news => new NewsModuleNews(news));
  } catch (error) {
    if (error instanceof AxiosError) {
      const fupaApiError = FuPaApiError.fromAxiosError(error);
      fupaApiError.logClientError('Top-News Module');
    }
    throw error;
  }
};

const fetchTopNewsListSSR = (slug: string) => {
  return async function (dispatch: any) {
    try {
      const response = await fetchDistrictNews(slug, topNewsOptions);
      dispatch(
        updateHistory('undefined', {
          store: 'DistrictMainPage',
          data: {topNews: response.data},
        })
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        const fupaApiError = FuPaApiError.fromAxiosError(error);
        if (fupaApiError.isTimeout()) {
          fupaApiError.logError();
          fupaApiError.status = 500;
        }
        dispatch(
          updateHistory('undefined', {
            store: 'DistrictMainPage',
            data: {topNews: fupaApiError.toPlain()},
          })
        );
      } else {
        throw error;
      }
    }
  };
};

const fetchNewsList = async (slug: string, url: string | null, dispatch: any): Promise<DistrictNewsResponse> => {
  try {
    const response = url
      ? (await fetchNextApiCall<DistrictNewsResponseRaw>(api, url)).data
      : await fetchDistrictNews(slug);
    return new DistrictNewsResponse(response);
  } catch (error) {
    if (error instanceof AxiosError) {
      const fupaApiError = FuPaApiError.fromAxiosError(error);
      fupaApiError.logClientError('News-Stream');

      if (!url) {
        dispatch(updateHttpError(fupaApiError.toPlain()));
      }
    }
    throw error;
  }
};

const fetchNewsListSSR = (slug: string, page: string | undefined) => {
  return async function (dispatch: any) {
    try {
      const response = await fetchDistrictNews(slug, {page: page ? Number(page) : 1});
      dispatch(
        updateHistory('undefined', {
          store: 'DistrictAllNewsPage',
          data: response,
        })
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        const fupaApiError = FuPaApiError.fromAxiosError(error);
        dispatch(updateHttpError(fupaApiError.toPlain()));
      } else {
        throw error;
      }
    }
  };
};

export {fetchTopNewsList, fetchTopNewsListSSR, fetchNewsList, fetchNewsListSSR};
