export enum AdUnit {
  Content_1 = 'Content_1',
  Content_2 = 'Content_2',
  Rectangle_1 = 'Rectangle_1',
  Rectangle_2 = 'Rectangle_2',
  Native_1 = 'Native_1',
  Native_2 = 'Native_2',
  Superbanner = 'Superbanner',
  Superbanner_2 = 'Superbanner_2',
  Superbanner_3 = 'Superbanner_3',
  Skyscraper = 'Skyscraper',
  Skyscraper_2 = 'Skyscraper_2',
  Sticky_Mobile_Banner = 'Sticky_Mobile_Banner',
  Mobile_Banner = 'Mobile_Banner',
  EdW_Banner_Top = 'EdW_Banner_Top',
  EdW_Banner_Bottom = 'EdW_Banner_Bottom',
  EdW_Banner_Center = 'EdW_Banner_Center',
  MatchEvent_1 = 'MatchEvent_1',
}
