import Linkable from 'app/models/Linkable';
import PlayerInterface from 'app/models/player/PlayerInterface';

export default class PlayerLink implements Linkable {
  constructor(private readonly player: PlayerInterface) {}

  getLink(): string {
    return `/player/${this.player.slug}`;
  }
}
