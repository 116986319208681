import {PaginatedResponse} from 'app/models/pagination/PaginatedResponse';
import CompetitionModuleDataResource from 'app/components/competitionModule/models/CompetitionModuleDataResource';
import CompetitionModuleData from 'app/components/competitionModule/models/CompetitionModuleData';
import {PaginatedResponseRaw} from 'app/models/pagination/PaginatedResponseRaw';

export interface DistrictCompetitionsResponseRaw extends PaginatedResponseRaw {
  data: CompetitionModuleDataResource[];
}

export default class DistrictCompetitionsResponse extends PaginatedResponse {
  data: CompetitionModuleData[];
  currentPageStartIndex: number;

  constructor(data: DistrictCompetitionsResponseRaw) {
    super(data);
    this.currentPageStartIndex = this.meta.from;
    this.data = data.data.map(
      (competition, index) =>
        new CompetitionModuleData({...competition, moduleIndex: index + this.currentPageStartIndex - 1})
    );
  }

  // this method is used in useQueryCompetitionModules.ts
  getNextPageParam(): string | undefined {
    return this.links.next ?? undefined;
  }
}
