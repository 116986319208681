import styled from 'styled-components';
import {SIZES} from '@fupa/fupa-uikit';

const OnlyDesktop = styled.div`
  display: none;
  ${props => props.theme.desktopLayout`
    display: block;
  `}
`;

const OnlyMobile = styled.div`
  display: block;
  ${props => props.theme.desktopLayout`
    display: none;
  `}
`;

const View = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${SIZES['16']};
  padding: ${SIZES['16']} 0 ${SIZES['24']};
  ${props => props.theme.desktopLayout`
    max-height: 240px;
    flex-wrap: wrap;
  `}
`;

export {OnlyMobile, OnlyDesktop, View};
