import {useEffect} from 'react';
import posthog from 'posthog-js';
import {useLocation} from 'react-router';

const defaultSampleRate = 0.1;
const useInitPosthog = (customSampleRate?: number) => {
  const {search} = useLocation();
  const forceInitPosthog = search.includes('init_posthog');

  useEffect(() => {
    if (process.env.ENVIRONMENT === 'prod') {
      const sampleRate = customSampleRate ?? defaultSampleRate;
      const shouldInitialize = forceInitPosthog || Math.random() <= sampleRate;
      if (shouldInitialize) {
        // @ts-ignore
        posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
          api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
          disable_surveys: true,
          disable_session_recording: true,
          persistence: 'memory',
          capture_heatmaps: true,
          ip: false,
        });
      }
    }
  }, []);
};

export default useInitPosthog;
