import NewsModuleNews, {NewsModuleNewsInterface} from 'app/components/topNewsModule/models/NewsModuleNews';
import MediaInterface from 'app/components/mediaModule/modules/MediaInterface';
import DistrictCompetitionsResponse, {
  DistrictCompetitionsResponseRaw,
} from 'app/components/competitionModule/models/DistrictCompetitionsResponse';
import {MediaResource} from 'app/components/mediaModule/modules/MediaResource';
import MediaFactory from 'app/components/mediaModule/modules/MediaFactory';
import {captureSentryException} from 'app/services/sentryLogging';
import DistrictMainPageStateInterface from 'app/routes/district/mainPage/models/DistrictMainPageStateInterface';
import RestoredData from 'app/routes/district/mainPage/models/DistrictMainPageRestoreData';
import FuPaApiError from 'app/models/error/FuPaApiError';
import {FuPaApiErrorInterface} from 'app/models/error/FuPaApiErrorInterface';
import Transfer from 'app/models/transfer/Transfer';
import TransferResource from 'app/resources/transfer/TransferResource';

export default class DistrictMainPageState implements DistrictMainPageStateInterface {
  topNews: NewsModuleNews[] | FuPaApiError | undefined;
  media: MediaInterface[] | FuPaApiError | undefined;
  competitions: DistrictCompetitionsResponse | FuPaApiError | undefined;
  transfers: Transfer[] | FuPaApiError | undefined;

  constructor(restoredData: RestoredData = {}) {
    const {topNews, media, competitions, transfers} = restoredData;
    this.topNews = this.createTopNews(topNews);
    this.media = this.createMedia(media);
    this.competitions = this.createCompetitions(competitions);
    this.transfers = this.createTransfers(transfers);
  }

  private isFuPaApiErrorInterface(
    data:
      | NewsModuleNewsInterface[]
      | MediaResource[]
      | DistrictCompetitionsResponseRaw
      | TransferResource[]
      | FuPaApiErrorInterface
  ): data is FuPaApiErrorInterface {
    return 'status' in data && 'message' in data;
  }

  private createTopNews(
    topNews: NewsModuleNewsInterface[] | FuPaApiErrorInterface | undefined
  ): NewsModuleNews[] | FuPaApiError | undefined {
    if (!topNews) {
      return undefined;
    }
    if (this.isFuPaApiErrorInterface(topNews)) {
      return new FuPaApiError(topNews);
    }
    return topNews.map((news: NewsModuleNewsInterface) => new NewsModuleNews(news));
  }

  private createMedia(
    media: MediaResource[] | FuPaApiErrorInterface | undefined
  ): MediaInterface[] | FuPaApiError | undefined {
    if (!media) {
      return undefined;
    }
    if (this.isFuPaApiErrorInterface(media)) {
      return new FuPaApiError(media);
    }
    return media
      .map((mediaData: MediaResource) => {
        try {
          return MediaFactory.create(mediaData);
        } catch (e) {
          captureSentryException(e);
          return null;
        }
      })
      .filter((element): element is MediaInterface => element !== null);
  }

  private createCompetitions(
    competitions: DistrictCompetitionsResponseRaw | FuPaApiErrorInterface | undefined
  ): DistrictCompetitionsResponse | FuPaApiError | undefined {
    if (!competitions) {
      return undefined;
    }
    if (this.isFuPaApiErrorInterface(competitions)) {
      return new FuPaApiError(competitions);
    }
    return new DistrictCompetitionsResponse(competitions);
  }

  private createTransfers(
    transfers: TransferResource[] | FuPaApiErrorInterface | undefined
  ): Transfer[] | FuPaApiError | undefined {
    if (!transfers) {
      return undefined;
    }
    if (this.isFuPaApiErrorInterface(transfers)) {
      return new FuPaApiError(transfers);
    }
    return transfers.map((transfer: TransferResource) => new Transfer(transfer));
  }
}
