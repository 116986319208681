import styled from 'styled-components';
import {SIZES} from '@fupa/fupa-uikit';

const Wrapper = styled.div`
  width: inherit;
  display: flex;
  flex-direction: column;
`;

const MatchesView = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 160px);
  justify-content: space-around;
  row-gap: ${SIZES['8']};
  padding: 0 ${SIZES['16']} ${SIZES['10']};

  @media (max-width: 359px) {
    padding: 0 ${SIZES['8']} ${SIZES['10']};
  }

  @media (max-width: 349px) {
    grid-template-columns: repeat(2, 144px);
  }
`;

export {Wrapper, MatchesView};
