import styled from 'styled-components';
import {COLORS, SIZES, Typography} from '@fupa/fupa-uikit';

const View = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  padding: ${SIZES['8']} ${SIZES['16']};
  gap: ${SIZES['8']};

  ${props =>
    props.theme.desktopLayout`
        gap: ${SIZES['16']};
      `}
`;

const Wrapper = styled.div<{withBgColor?: boolean}>`
  position: relative;
  width: 100%;
  aspect-ratio: 3/2;
  border-radius: ${SIZES['8']};
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: ${props => (props.withBgColor ? COLORS.black : 'transparent')};
  cursor: pointer;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const DateOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: ${SIZES['4']} ${SIZES['8']};
  text-align: right;
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
`;

const ContentOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: ${SIZES['16']} ${SIZES['8']} ${SIZES['4']};
  text-align: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  display: flex;
  flex-direction: column;

  ${props =>
    props.theme.desktopLayout`
        gap: ${SIZES['4']};
        padding: ${SIZES['8']} ${SIZES['16']};
      `}
`;

const WhiteTypography = styled(Typography)`
  color: white;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${SIZES['10']};

  ${props =>
    props.theme.streamModuleMedium`
        font-size: ${SIZES['12']};
      `}

  ${props =>
    props.theme.desktopLayout`
        font-size: ${SIZES['14']};
      `}
`;

const IconContainer = styled.span`
  display: inline-flex;
  margin-right: ${SIZES['4']};

  ${props =>
    props.theme.streamModuleMedium`
        margin-right: ${SIZES['8']};
      `}
`;

const Title = styled(Typography)`
  font-size: ${SIZES['12']};
  color: white;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  ${props =>
    props.theme.streamModuleMedium`
        font-size: ${SIZES['14']};
      `}

  ${props =>
    props.theme.desktopLayout`
        font-size: ${SIZES['16']};
      `}
`;

export {View, Wrapper, ImageContainer, DateOverlay, ContentOverlay, WhiteTypography, IconContainer, Title};
