import {useInfiniteQuery, UseInfiniteQueryResult} from '@tanstack/react-query';
import {fetchCompetitionModuleData} from 'app/queries/districtCompetitions';
import {AxiosError} from 'axios';
import {DefaultApiErrorResponse} from 'app/common/errorTypes';
import DistrictCompetitionsResponse from 'app/components/competitionModule/models/DistrictCompetitionsResponse';
import {useMemo} from 'react';
import InfiniteCompetitionModules from 'app/components/competitionModule/models/InfiniteCompetitionModules';
import FuPaApiError from 'app/models/error/FuPaApiError';

type ReturnType = {
  infiniteResult:
    | UseInfiniteQueryResult<DistrictCompetitionsResponse, AxiosError<DefaultApiErrorResponse, any>>
    | FuPaApiError;
  infiniteCompetitionModules: InfiniteCompetitionModules;
};

export const useQueryCompetitionModules = (
  slug: string,
  initialData: DistrictCompetitionsResponse | FuPaApiError | undefined
): ReturnType => {
  if (initialData && initialData instanceof FuPaApiError) {
    return {infiniteResult: initialData, infiniteCompetitionModules: new InfiniteCompetitionModules(undefined)};
  }
  const infiniteResult = useInfiniteQuery({
    queryKey: ['competitions', {slug}],
    queryFn: ({pageParam = null}) => fetchCompetitionModuleData(slug, pageParam),
    getNextPageParam: lastPage => lastPage.getNextPageParam(),
    initialData: initialData ? {pages: [initialData], pageParams: [undefined]} : undefined,
    onError: (error: AxiosError<DefaultApiErrorResponse>) => error, // only used for typing purposes
  });

  const infiniteCompetitionModules = useMemo(
    () => new InfiniteCompetitionModules(infiniteResult.data),
    [infiniteResult.data]
  );

  return {infiniteResult, infiniteCompetitionModules};
};
