import styled from 'styled-components';

export const SkeletonPlaceholderWrapper = styled.div`
  position: relative;
`;

export const SkeletonPlaceholderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const SkeletonPlaceholderDataContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  width: 75%;
`;
