import {useMemo} from 'react';
import {useLocation} from 'react-router';

export const useSearchParam = (key: string): string | null => {
  const location = useLocation();
  return useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(key);
  }, [location.search]);
};
