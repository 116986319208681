import TransferTeamResource from 'app/resources/transfer/TransferTeamResource';
import {ImageInterface} from 'app/types/ImageInterface';
import {convertToRoman} from 'app/helpers/convertToRoman';

export default class TransferTeam {
  private readonly id: number;
  private readonly slug: string;
  private readonly image: ImageInterface;
  private readonly fullName: string;
  private readonly middleName: string;
  private readonly level: number;

  constructor(resource: TransferTeamResource) {
    this.id = resource.id;
    this.slug = resource.slug;
    this.image = resource.image;
    this.fullName = resource.fullName;
    this.middleName = resource.middleName;
    this.level = resource.level;
  }

  getLink(): string {
    return `/team/${this.slug}`;
  }

  getName(): string {
    const level = this.getLevel();
    if (level) {
      return `${this.middleName} ${level}`;
    }
    return this.middleName;
  }

  getEmblem(): ImageInterface {
    return this.image;
  }

  private getLevel(): string | null {
    if (this.level <= 1) {
      return null;
    }
    return convertToRoman(this.level);
  }
}
