import styled from 'styled-components';
import {LinkOnComponent, LinkOnComponentProps} from 'app/components/links/link';
import {COLORS, FONTS, SIZES} from '@fupa/fupa-uikit';

const Wrapper = styled(LinkOnComponent)<LinkOnComponentProps>`
  position: relative;

  ${props =>
    props.theme.desktopLayout`
      margin: ${SIZES['8']} ${SIZES['16']} ${SIZES['16']} ${SIZES['16']};
    `}
`;

const TextOverlay = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  padding: ${SIZES['16']};

  ${props =>
    props.theme.desktopLayout`
      padding: ${SIZES['32']} ${SIZES['16']};
      bottom: ${SIZES['42']};
      width: 50%;
      background: rgba(0, 0, 0, 0.6);
      max-width: 31.25rem;
    `}
`;

const Title = styled.span`
  height: auto;
  padding-bottom: ${SIZES['8']};

  font-family: ${FONTS.Oswald};
  font-size: ${SIZES['24']};
  color: ${COLORS.white};

  /* Overflow after two lines */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  ${props =>
    props.theme.desktopLayout`
      font-size: ${SIZES['42']};
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-line-clamp: none;
    `};
`;

const Subject = styled.span`
  font-family: ${FONTS.PTSans};
  font-size: ${SIZES['14']};
  color: white;

  ${props =>
    props.theme.desktopLayout`
      font-size: ${SIZES['24']};
    `};
`;

const PublishDate = styled.span`
  font-family: ${FONTS.PTSans};
  font-size: ${SIZES['12']};
  color: white;

  ${props =>
    props.theme.desktopLayout`
      font-size: ${SIZES['14']};
    `};
`;

const ImageContainer = styled.div`
  height: 56.25vw;
  width: 100%;
  overflow: hidden;
  padding: 0;
  max-width: ${props => props.theme.desktopContentWidth}px;
  max-height: ${props => (props.theme.desktopContentWidth * 9) / 16}px;
  ${props => props.theme.desktopLayout`
    border-radius: ${SIZES['4']}
  `}
`;

export {Wrapper, TextOverlay, Title, Subject, PublishDate, ImageContainer};
