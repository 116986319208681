import DateModel from 'app/models/DateModel';
import NewsInterface from 'app/models/news/NewsInterface';
import NewsModel from 'app/models/news/NewsModel';

export interface CompetitionModuleNewsInterface extends NewsInterface {
  subtitle: string;
}

export default class CompetitionModuleNews {
  readonly news: NewsModel;
  readonly subtitle: string;

  constructor(data: CompetitionModuleNewsInterface) {
    this.news = new NewsModel(data);
    this.subtitle = data.subtitle;
  }

  getLink(): {pathname: string; state?: {updatedAt: number}} {
    return this.news.getLink();
  }

  formatDateRelative(relativeTo?: DateModel): string {
    return this.news.formatPublishDateRelative(relativeTo);
  }
}
