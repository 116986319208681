import {AxiosError, AxiosResponse} from 'axios';
import {api} from 'app/services/api';
import DistrictCompetitionsResponse, {
  DistrictCompetitionsResponseRaw,
} from 'app/components/competitionModule/models/DistrictCompetitionsResponse';
import {fetchNextApiCall} from 'app/services/fetchNextApiCall';
import {updateHistory} from 'app/actions/historyActions';
import FuPaApiError from 'app/models/error/FuPaApiError';

interface DistrictCompetitionsOptions {
  page?: number;
  limit?: number;
  offset?: number;
}

const getParamsStringFromOptions = (options: DistrictCompetitionsOptions): string => {
  return Object.keys(options)
    .map(option => (options[option] ? `${option}=${options[option]}` : null))
    .filter(Boolean)
    .join('&');
};

const fetchDistrictCompetitions = async (
  slug: string,
  options?: DistrictCompetitionsOptions
): Promise<DistrictCompetitionsResponseRaw> => {
  let url = `/districts/${slug}/competitions${options ? `?${getParamsStringFromOptions(options)}` : ''}`;
  const response: AxiosResponse<DistrictCompetitionsResponseRaw> = await api.get(url);
  return response.data;
};

const mediaOptions = {limit: 3};

const fetchCompetitionModuleData = async (slug: string, url: string | null): Promise<DistrictCompetitionsResponse> => {
  try {
    const response = url
      ? (await fetchNextApiCall<DistrictCompetitionsResponseRaw>(api, url)).data
      : await fetchDistrictCompetitions(slug, mediaOptions);
    return new DistrictCompetitionsResponse(response);
  } catch (error) {
    if (error instanceof AxiosError) {
      const fupaApiError = FuPaApiError.fromAxiosError(error);
      fupaApiError.logClientError('Competition Module');
    }
    throw error;
  }
};

const fetchCompetitionModuleDataSSR = (slug: string, page: string | undefined) => {
  return async function (dispatch: any) {
    try {
      const pageParam = page && page !== '1' ? Number(page) : undefined;
      const response = await fetchDistrictCompetitions(slug, {
        page: pageParam,
        limit: 3,
      });
      dispatch(
        updateHistory('undefined', {
          store: 'DistrictMainPage',
          data: {competitions: response},
        })
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        const fupaApiError = FuPaApiError.fromAxiosError(error);
        if (fupaApiError.isTimeout()) {
          fupaApiError.logError();
          fupaApiError.status = 500;
        }
        dispatch(
          updateHistory('undefined', {
            store: 'DistrictMainPage',
            data: {competitions: fupaApiError.toPlain()},
          })
        );
      } else {
        throw error;
      }
    }
  };
};

export {fetchCompetitionModuleData, fetchCompetitionModuleDataSSR};
