import DateModel from 'app/models/DateModel';
import PlayerModel from 'app/models/player/PlayerModel';
import TransferTeam from 'app/models/transfer/TransferTeam';
import TransferResource from 'app/resources/transfer/TransferResource';
import {ProfileRole, ProfileRoleDisplayNames} from 'app/models/profile/ProfileRole';
import {ImageInterface} from 'app/types/ImageInterface';
import {TransferTimeStatus} from 'app/components/transferModule/types/TransferTimeStatus';
import {TransferProfileRole} from 'app/components/transferModule/types/TransferProfileRole';
import {captureSentryException} from 'app/services/sentryLogging';

export default class Transfer {
  private readonly id: number;
  private readonly profile: PlayerModel;
  private readonly transferDate: DateModel;
  private readonly reportDate: DateModel;
  readonly fromTeam: TransferTeam | null;
  private readonly fromAlternative: string | null;
  readonly toTeam: TransferTeam | null;
  private readonly toAlternative: string | null;
  readonly note: string | null;
  private readonly timeStatus: TransferTimeStatus;
  private readonly type: TransferProfileRole;
  private readonly secondPlayingRight: boolean;

  constructor(data: TransferResource) {
    this.id = data.id;
    this.profile = new PlayerModel(data.profile);
    this.transferDate = DateModel.fromIso8601(data.transferDate);
    this.reportDate = DateModel.fromIso8601(data.reportDate);
    this.fromTeam = data.fromTeam ? new TransferTeam(data.fromTeam) : null;
    this.fromAlternative = data.fromAlternative;
    this.toTeam = data.toTeam ? new TransferTeam(data.toTeam) : null;
    this.toAlternative = data.toAlternative;
    this.note = data.note;
    this.timeStatus = data.timeStatus;
    this.type = data.type;
    this.secondPlayingRight = data.secondPlayingRight;
  }

  isValid(): boolean {
    if (!this.fromTeam && !this.toTeam) {
      captureSentryException(new Error(`Invalid transfer data: there are no teams linked to the transfer ${this.id}`));
      return false;
    }
    if (!this.isValidType()) {
      captureSentryException(
        new Error(`Invalid transfer data: found a transfer (id: ${this.id}) with the invalid type ${this.type}`)
      );
      return false;
    }
    return true;
  }

  getKey(): number {
    return this.id;
  }

  getLink(): string {
    return this.profile.getLink();
  }

  getProfileImage(): ImageInterface {
    return this.profile.image;
  }

  getPlayerName(): string {
    return this.profile.getName();
  }

  isSecondPlayingRight(): boolean {
    return this.secondPlayingRight;
  }

  isCoach(): boolean {
    return this.type === ProfileRole.Coach || this.type === ProfileRole.AssistantCoach;
  }

  getTransferEmoji(): string {
    if (this.secondPlayingRight) {
      return '🦦';
    }
    return '➡️';
  }

  private getFromTeamName(): string | null {
    if (this.fromTeam) {
      return this.fromTeam.getName();
    }
    if (this.fromAlternative) {
      return this.fromAlternative;
    }
    return null;
  }

  private getToTeamName(): string | null {
    if (this.toTeam) {
      return this.toTeam.getName();
    }
    if (this.toAlternative) {
      return this.toAlternative;
    }
    return null;
  }

  getTransferDescription(): string {
    switch (this.type) {
      case ProfileRole.Player:
        return this.getPlayerTransferDescription();
      case ProfileRole.Coach:
      case ProfileRole.AssistantCoach:
        return this.getCoachTransferDescription();
      case ProfileRole.PlayerCoach:
      case ProfileRole.AssistantPlayerCoach:
        return this.getPlayerCoachTransferDescription();
      default:
        throw new Error(`Unknown profile role for transfer description: ${this.type}`);
    }
  }

  getDatePrefix(): string | null {
    if (this.isTransferWithRole() && !!this.getFromTeamName() && !!this.getToTeamName()) {
      return `als ${ProfileRoleDisplayNames[this.type]} - `;
    }
    return null;
  }

  private isTransferWithRole(): boolean {
    return this.type !== ProfileRole.Player;
  }

  getTransferDate(): string {
    if (this.timeStatus === TransferTimeStatus.Instant) {
      return 'ab sofort';
    }
    const formatedDate = this.transferDate.formatMonthYear();
    return `zum ${formatedDate}`;
  }

  formatReportDate(): string {
    return this.reportDate.getRelativeTime();
  }

  private getPlayerTransferDescription(): string {
    const from = this.getFromTeamName();
    const to = this.getToTeamName();
    if (this.secondPlayingRight && to) {
      return `Zweitspielrecht bei ${to}`;
    }
    if (from && to) {
      return `von ${from} nach ${to}`;
    }
    if (from) {
      return `Abgang ${from}`;
    }
    if (to) {
      return `nach ${to}`;
    }
    throw new Error('Invalid transfer data: there are no teams linked to this transfer');
  }

  private getCoachTransferDescription(): string {
    const from = this.getFromTeamName();
    const to = this.getToTeamName();
    const displayName = ProfileRoleDisplayNames[this.type];
    if (from && to) {
      return `von ${from} nach ${to}`;
    }
    if (to) {
      return `Neuer ${displayName} bei ${to}`;
    }
    if (from) {
      return `Nicht mehr ${displayName} bei ${from}`;
    }
    throw new Error('Invalid transfer data: there are no teams linked to this transfer');
  }

  private getPlayerCoachTransferDescription(): string {
    const from = this.getFromTeamName();
    const to = this.getToTeamName();
    const displayName = ProfileRoleDisplayNames[this.type];
    if (to && from) {
      return `von ${from} nach ${to}`;
    }
    if (to) {
      return `Neuer ${displayName} bei ${to}`;
    }
    if (from) {
      return `Nicht mehr ${displayName} bei ${from}`;
    }
    throw new Error('Invalid transfer data: there are no teams linked to this transfer');
  }

  private isValidType(): boolean {
    return [
      ProfileRole.Player,
      ProfileRole.Coach,
      ProfileRole.AssistantCoach,
      ProfileRole.PlayerCoach,
      ProfileRole.AssistantPlayerCoach,
    ].includes(this.type);
  }
}
