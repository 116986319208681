// noinspection CssUnresolvedCustomProperty

import styled from 'styled-components';
import {LinkOnComponent} from 'app/components/links/link';
import {COLORS, FONTS, SIZES, Typography} from '@fupa/fupa-uikit';

const Container = styled.div`
  --padding-sm: ${SIZES['8']};
  --padding-lg: ${SIZES['16']};
  --element-xs: 100px;
  --element-sm: 110px;
  --element-md: 120px;
  --element-lg: 160px;
  --element-xl: 216px;

  display: flex;
  padding: ${SIZES['8']} var(--padding-sm);

  gap: calc((100vw - (var(--padding-sm) * 2) - (var(--element-xs) * 3)) / 2);

  ${props => props.theme.streamModuleSmall`
    gap: calc((100vw - (var(--padding-sm) * 2) - (var(--element-sm) * 3))/2);
  `}

  ${props => props.theme.streamModuleMedium`
    gap: calc((100vw - (var(--padding-sm) * 2) - (var(--element-md) * 3))/2);
  `}

  ${props => props.theme.streamModuleFourElemsPerViews`
    justify-content: space-between;
    gap: normal;
  `}

  ${props => props.theme.desktopLayout`
    padding: ${SIZES['8']} var(--padding-lg);
  `}
`;

const SubNewsWrapper = styled.div`
  width: var(--element-xs);

  ${props => props.theme.streamModuleSmall`
    width: var(--element-sm);
  `}

  ${props => props.theme.streamModuleMedium`
    width: var(--element-md);
  `}

  ${props => props.theme.streamModuleLarge`
    width: var(--element-lg);
  `}

  ${props => props.theme.desktopLayout`
    width: var(--element-xl);
  `}
`;

const Link = styled(LinkOnComponent)`
  display: flex;
  flex-direction: column;
  width: inherit;
  height: inherit;

  ${props => props.theme.desktopLayout`
    gap: ${SIZES['4']};
  `}
`;

const ImageContainer = styled.div`
  position: relative;
  width: var(--element-xs);
  height: var(--element-xs);
  border-radius: ${SIZES['4']};
  margin-bottom: ${SIZES['8']};
  overflow: hidden;

  ${props => props.theme.streamModuleSmall`
    width: var(--element-sm);
    height: var(--element-sm);
  `}

  ${props => props.theme.streamModuleMedium`
    width: var(--element-md);
    height: var(--element-md);
  `}

  ${props => props.theme.streamModuleLarge`
    width: var(--element-lg);
    height: var(--element-lg);
  `}

  ${props => props.theme.desktopLayout`
    width: var(--element-xl);
    height: var(--element-xl);
  `}
`;

const ClampedLineTitle = styled.span`
  flex: 1;
  margin-bottom: 0.5rem;

  font-family: ${FONTS.Oswald};
  font-size: ${SIZES['14']};
  color: ${COLORS.black};

  /* overflow after 3 lines */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  ${props => props.theme.desktopLayout`
      margin: 0;
      font-size: ${SIZES['16']};
    `}
`;

const Caption = styled(Typography)`
  &&& {
    color: ${COLORS.darkerGrey};
    ${props => props.theme.desktopLayout`
      font-size: ${SIZES['14']};
    `};
  }
`;

export {SubNewsWrapper, Link, ImageContainer, ClampedLineTitle, Caption, Container};
