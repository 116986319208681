import NewsModuleNews, {NewsModuleNewsInterface} from 'app/components/topNewsModule/models/NewsModuleNews';
import {PaginatedResponse} from 'app/models/pagination/PaginatedResponse';
import {PaginatedResponseRaw} from 'app/models/pagination/PaginatedResponseRaw';

export interface DistrictNewsResponseRaw extends PaginatedResponseRaw {
  data: NewsModuleNewsInterface[];
}

export default class DistrictNewsResponse extends PaginatedResponse {
  data: NewsModuleNews[];

  constructor(data: DistrictNewsResponseRaw) {
    super(data);
    this.data = data.data.map(news => new NewsModuleNews(news));
  }
}
