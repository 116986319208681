import styled from 'styled-components';
import {SIZES} from '@fupa/fupa-uikit';

export const CompetitionModuleList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${SIZES['16']};

  ${props =>
    props.theme.desktopLayout`
      justify-content: space-between;
    `}
`;
