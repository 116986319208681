import styled from 'styled-components';
import {LinkOnComponent} from 'app/components/links/link';

const Heading = styled(LinkOnComponent)`
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
`;

export {Heading};
