import styled from 'styled-components';
import {COLORS, FONTS, SIZES} from '@fupa/fupa-uikit';
import {desktopContentWidth} from 'app/styles/media';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const NewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SIZES['8']};
  padding: 0 ${SIZES['16']} ${SIZES['16']};
`;

/* NewsComponent */

const ComponentWrapper = styled.div`
  display: flex;
  max-width: ${desktopContentWidth / 2 - 32}px;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: ${SIZES['4']};
  overflow: hidden;
`;

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${SIZES['4']} 0 ${SIZES['4']} ${SIZES['12']};
  justify-content: center;
  gap: ${SIZES['4']};
`;

const ClampedLineTitle = styled.span`
  font-family: ${FONTS.Oswald};
  font-size: ${SIZES['14']};
  color: ${COLORS.black};

  /* overflow after 2 lines */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${props => props.theme.streamModuleFourElemsPerViews`
      font-size: ${SIZES['16']};
    `}
`;

const ClampedLineSubtitle = styled.span`
  font-family: ${FONTS.PTSans};
  font-size: ${SIZES['12']};
  color: ${COLORS.darkerGrey};

  /* overflow after 2 lines */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${props => props.theme.streamModuleFourElemsPerViews`
      font-size: ${SIZES['14']};
    `}
`;

export {Wrapper, NewsContainer, ComponentWrapper, ImageContainer, TextContainer, ClampedLineTitle, ClampedLineSubtitle};
