import styled, {css} from 'styled-components';
import {Skeleton} from '@mui/material';

const FuPaSkeleton = styled(Skeleton)`
  ${props =>
    !props.animation &&
    css`
      &&& {
        animation: none;
        background-color: rgba(0, 0, 0, 0.11);
      }
    `}
`;

export default FuPaSkeleton;
