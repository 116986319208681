import styled from 'styled-components';
import {LinkOnComponent} from 'app/components/links/link';
import {SIZES} from '@fupa/fupa-uikit';

const Wrapper = styled(LinkOnComponent)`
  &&& {
    display: flex;
    align-items: center;
    gap: ${SIZES['8']};
    padding: 0 ${SIZES['16']};
    overflow: hidden;
    width: 100%;
    ${props => props.theme.desktopLayout`
    max-width: 477px;
  `}
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
`;

export {Wrapper, TextContainer};
