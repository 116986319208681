import * as Sentry from '@sentry/react';
import {COLORS} from '@fupa/fupa-uikit';
import {useEffect} from 'react';

const feedbackInstance = Sentry.feedbackAsyncIntegration({
  autoIncjet: false,
  submitButtonLabel: 'Senden',
  formTitle: 'Teile uns deine Meinung mit 💬',
  triggerLabel: 'Feedback geben',
  addScreenshotButtonLabel: 'Bildschirmfoto hinzufügen',
  removeScreenshotButtonLabel: 'Bildschirmfoto entfernen',
  confirmButtonLabel: 'Bestätigen',
  cancelButtonLabel: 'Abbrechen',
  successMessageText: 'Danke für dein Feedback!',
  messagePlaceholder: 'Was können wir verbessern? Möchtest du ein Problem melden?',
  messageLabel: 'Dein Feedback',
  isRequiredLabel: '(erforderlich)',
  showName: false,
  showEmail: false,
  showBranding: false,
  colorScheme: 'light',
  themeLight: {
    background: COLORS.white,
    accentBackground: COLORS.fupaSecondary,
  },
});

const initSentryUserFeedback = () => {
  useEffect(() => {
    feedbackInstance.attachTo(document.querySelector('#fupa-feedback-btn'));
  }, []);
};

export {initSentryUserFeedback};
