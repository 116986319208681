import NewsInterface from 'app/models/news/NewsInterface';
import NewsLink from 'app/models/news/NewsLink';
import {ImageInterface} from 'app/types/ImageInterface';
import DateModel from 'app/models/DateModel';

export default class NewsModel {
  readonly id: number;
  readonly slug: string;
  readonly title: string;
  readonly image: ImageInterface;
  private readonly publishDate: DateModel;
  private readonly updatedAt: DateModel;
  private readonly link: NewsLink;

  constructor(data: NewsInterface) {
    this.id = data.id;
    this.slug = data.slug;
    this.title = data.title;
    this.image = data.image;
    this.publishDate = DateModel.fromIso8601(data.publishDate);
    this.updatedAt = DateModel.fromIso8601(data.updatedAt);
    this.link = new NewsLink(data);
  }

  getLink(): {pathname: string; state?: {updatedAt: number}} {
    const linkUrl = this.link.getLink();
    const locationState = this.link.getLocationState();
    return {pathname: linkUrl, state: locationState};
  }

  formatPublishDateRelative(relativeTo?: DateModel): string {
    return this.publishDate.getRelativeTime(relativeTo);
  }
}
