import getParamsStringFromOptions, {QueryOptions} from 'app/queries/common/getParamStringFromOptions';
import {AxiosError, AxiosResponse} from 'axios';
import {api} from 'app/services/api';
import DistrictTransfersResponseResource from 'app/resources/district/transfers/DistrictTransfersResponseResource';
import Transfer from 'app/models/transfer/Transfer';
import FuPaApiError from 'app/models/error/FuPaApiError';
import DistrictTransfersResponse from 'app/models/district/transfers/DistrictTransfersResponse';
import {updateHistory} from 'app/actions/historyActions';
import {fetchNextApiCall} from 'app/services/fetchNextApiCall';
import {updateHttpError} from 'app/components/error/ErrorHandlerActions';

const getDistrictTransfers = async (
  slug: string,
  options?: QueryOptions
): Promise<DistrictTransfersResponseResource> => {
  let url = `/districts/${slug}/transfers${options ? `?${getParamsStringFromOptions(options)}` : ''}`;
  const response: AxiosResponse<DistrictTransfersResponseResource> = await api.get(url);
  return response.data;
};

const recentTransfersOptions: QueryOptions = {maxDays: 10, limit: 24};

const fetchRecentTransfers = async (slug: string): Promise<Transfer[]> => {
  try {
    const responseRaw = await getDistrictTransfers(slug, recentTransfersOptions);
    const response = new DistrictTransfersResponse(responseRaw);
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      const fupaApiError = FuPaApiError.fromAxiosError(error);
      fupaApiError.logClientError('Transfer Module');
    }
    throw error;
  }
};

const fetchRecentTransfersSSR = (slug: string) => {
  return async function (dispatch: any) {
    try {
      const response = await getDistrictTransfers(slug, recentTransfersOptions);
      dispatch(
        updateHistory('undefined', {
          store: 'DistrictMainPage',
          data: {transfers: response.data},
        })
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        const fupaApiError = FuPaApiError.fromAxiosError(error);
        if (fupaApiError.isTimeout()) {
          fupaApiError.logError();
          fupaApiError.status = 500;
        }
        dispatch(
          updateHistory('undefined', {
            store: 'DistrictMainPage',
            data: {transfers: fupaApiError.toPlain()},
          })
        );
      } else {
        throw error;
      }
    }
  };
};

const fetchAllTransfers = async (
  slug: string,
  url: string | null,
  dispatch: any
): Promise<DistrictTransfersResponse> => {
  try {
    const response = url
      ? (await fetchNextApiCall<DistrictTransfersResponseResource>(api, url)).data
      : await getDistrictTransfers(slug);
    return new DistrictTransfersResponse(response);
  } catch (error) {
    if (error instanceof AxiosError) {
      const fupaApiError = FuPaApiError.fromAxiosError(error);
      fupaApiError.logClientError('Transfer-Stream');

      if (!url) {
        dispatch(updateHttpError(fupaApiError.toPlain()));
      }
    }
    throw error;
  }
};

const fetchAllTransfersSSR = (slug: string, page: string | undefined, storeName: string) => {
  return async function (dispatch: any) {
    try {
      const response = await getDistrictTransfers(slug, {page: page ? Number(page) : 1});
      dispatch(
        updateHistory('undefined', {
          store: storeName,
          data: response,
        })
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        const fupaApiError = FuPaApiError.fromAxiosError(error);
        dispatch(updateHttpError(fupaApiError.toPlain()));
      } else {
        throw error;
      }
    }
  };
};

export {fetchRecentTransfers, fetchRecentTransfersSSR, fetchAllTransfers, fetchAllTransfersSSR};
