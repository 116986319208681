import {sendEventToGA4} from 'app/helpers/gtagHelpers';
import {useCallback} from 'react';

export interface IDistrictMainPageTrackingProps {
  totalPagesCount: number;
  viewedPageIndex: number;
}

export enum DistrictMainPageModuleType {
  TOPNEWS = 'topNews',
  MEDIA = 'media',
  COMPETITION = 'competition',
}

export const useDistrictMainPageTracking = () => {
  const updateTrackingData = useCallback((module: DistrictMainPageModuleType, data: IDistrictMainPageTrackingProps) => {
    sendEventToGA4('fupa_viewed_pages', {
      content_type: module,
      index: data.viewedPageIndex,
      total: data.totalPagesCount,
      content_group: 'test_region_index',
    });
  }, []);

  const trackClickedItemPage = useCallback((module: DistrictMainPageModuleType, index: number) => {
    sendEventToGA4('select_content', {
      content_type: module,
      index,
      content_group: 'test_region_index',
    });
  }, []);

  return {updateTrackingData, trackClickedItemPage};
};
