import {ImageInterface} from 'app/types/ImageInterface';
import MatchModel from 'app/models/match/MatchModel';
import CompetitionModuleNews from 'app/components/competitionModule/models/CompetitionModuleNews';
import CompetitionModuleDataResource from 'app/components/competitionModule/models/CompetitionModuleDataResource';
import {SpecialCompetitions} from 'app/types/SpecialCompetitions';

export default class CompetitionModuleData {
  moduleIndex: number;
  id: number;
  middleName: string;
  matches: MatchModel[];
  news: CompetitionModuleNews[];
  slug: string;
  category: {
    id: number;
    name: string;
  };
  image: ImageInterface;
  longName: string;

  constructor(data: CompetitionModuleDataResource) {
    this.moduleIndex = data.moduleIndex;
    this.id = data.id;
    this.middleName = data.middleName;
    this.slug = data.slug;
    this.category = data.category;
    this.image = data.image;
    this.longName = data.longName;
    this.matches = data.matches.map(match => new MatchModel(match));
    this.news = data.news.map(news => new CompetitionModuleNews(news));
  }

  getSubrouteLink(): {url: string; rel: 'follow'; label: string} | null {
    if (this.isCompetitionWithoutLink()) {
      return null;
    }
    return {url: this.getLinkUrl(), rel: this.getLinkRel(), label: this.getLinkLabel()};
  }

  getLinkToCompetition(): string {
    return `/${this.getCompetitionCategoryPath()}/${this.slug}`;
  }

  private isCompetitionWithoutLink(): boolean {
    return this.slug === SpecialCompetitions.Testmatches || this.slug === SpecialCompetitions.Relegation;
  }

  private getLinkUrl(): string {
    const subroute = this.isLeague() ? 'standing' : 'matches';
    return `${this.getLinkToCompetition()}/${subroute}`;
  }

  private getLinkRel(): 'follow' {
    return 'follow';
  }

  private getLinkLabel(): string {
    return this.isLeague() ? 'Tabelle' : 'Spielplan/Tabelle';
  }

  private isLeague(): boolean {
    return this.category.id === 1;
  }

  private getCompetitionCategoryPath(): 'league' | 'cup' {
    return this.isLeague() ? 'league' : 'cup';
  }
}
