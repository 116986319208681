import {ReactElement} from 'react';
import {chunkArray} from 'app/helpers/chunkArray';
import {captureSentryException} from 'app/services/sentryLogging';

const createRenderedViewsFromArray = <T>(
  list: T[],
  elementCreator: (data: T, index: number) => ReactElement | null,
  fillerCreator: (index: number) => ReactElement,
  itemsPerView: number = 4,
  collapse: boolean = false
): ReactElement[][] => {
  const elements: ReactElement[] = list
    .map((data, index) => {
      try {
        return elementCreator(data, index);
      } catch (e) {
        captureSentryException(e);
        return null;
      }
    })
    .filter((element): element is ReactElement => element !== null);

  if (!elements.length) {
    return [];
  }

  const numberOfFillers = itemsPerView - (elements.length % itemsPerView);

  const fillers =
    collapse && elements.length < itemsPerView
      ? []
      : createArrayOfFillers(numberOfFillers, itemsPerView, fillerCreator);

  const elementsWithFillers: ReactElement[] = [...elements, ...fillers];

  return chunkArray(elementsWithFillers, itemsPerView);
};

const createArrayOfFillers = (
  numberOfFillers: number,
  itemsPerView: number,
  fillerCreator: (index: number) => ReactElement
): ReactElement[] => {
  if (numberOfFillers === 0 || numberOfFillers === itemsPerView) {
    return [];
  }
  return Array.from({length: numberOfFillers}, (_value, index) => fillerCreator(index));
};

export default createRenderedViewsFromArray;
