import {PaginatedResponse} from 'app/models/pagination/PaginatedResponse';
import DistrictTransfersResponseResource from 'app/resources/district/transfers/DistrictTransfersResponseResource';
import Transfer from 'app/models/transfer/Transfer';

export default class DistrictTransfersResponse extends PaginatedResponse {
  data: Transfer[];

  constructor(responseResource: DistrictTransfersResponseResource) {
    super(responseResource);
    const transfers = responseResource.data.map(transferResource => new Transfer(transferResource));
    this.data = transfers.filter(transfer => transfer.isValid());
  }
}
